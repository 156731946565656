import React, { useContext } from 'react';

import AppRoutes from './app.routes';
import AuthRoutes from './auth.routes';
import { FlowContext } from '../contexts/FlowContext';


function Routes() {
    const { token, flow } = useContext(FlowContext);

    return(
        <AuthRoutes />
    )
}

export default Routes;
