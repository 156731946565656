import React, {useContext, useState} from 'react';
import { View, Text, TextInput, StyleSheet, TouchableOpacity, ActivityIndicator } from 'react-native';
import { showMessage } from "react-native-flash-message";
import {FlowContext} from '../../contexts/FlowContext'

import { Styles } from '../../constants';


export default function ValidateEmail({navigation, route}) {
    const {validateEmail, setLoading, loading} = useContext(FlowContext);
    const [code, setCode] = useState<string>('');
    
    async function handleValidateEmail() {
        setLoading(true);
        var valid = await validateEmail(route.params?.email, code);
        if (!valid) {
            setLoading(false);
            showMessage({
                message: "Erro na validação de email",
                type: "danger",
            });
            return;
        }
        navigation.navigate('Step', {step_id: 1});
        setLoading(false);
    }

    if (loading){
        return (
            <View style={{
                flex:1,
                backgroundColor: 'white',
                justifyContent: 'center',
                alignItems: 'center'
                }}
            >
                <ActivityIndicator size={60} color='#3d3d3d' />
            </View>
        )
    }

    return (
        <View style={styles.container}>
            <View style={styles.inner_container}>
                <Text style={styles.title}>Digite o código de verificação enviado por e-mail</Text>
                    <TextInput
                        placeholder=""
                        style={styles.input}
                        placeholderTextColor="#F0F0F0"
                        value={code}
                        onChangeText={setCode}
                        onSubmitEditing={handleValidateEmail}
                        keyboardType='numeric'
                    />
                <TouchableOpacity style={styles.button} onPress={handleValidateEmail}>
                    <Text style={styles.buttonText}>Confirmar</Text>
                </TouchableOpacity>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: 'white'
    },
    inner_container: {
        width: '75%',
        alignItems: 'center'
    },
    title: {
        marginTop: 20,
        fontSize: Styles.titleSize,
        color: Styles.titleColor,
        marginBottom: 40,
        fontFamily: Styles.fontFamily
    },
    description: {
        marginTop: 20,
        fontSize: Styles.detailsSize,
        color: Styles.titleColor,
        marginBottom: 50,
        fontFamily: Styles.fontFamily
    },
    button: {
        width: '100%',
        height: 40,
        backgroundColor: '#1595D2',
        borderRadius: 4,
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonText: {
        fontSize: Styles.buttonTextSize,
        fontWeight: 'bold',
        color: 'white'
    },
    input: {
        fontSize: 24,
        fontWeight: 'normal',
        color: 'black',
        width: '100%',
        height: 50,
        borderColor: '#1595D2',
        borderRadius: 4,
        marginBottom: 20,
        borderWidth: 1,
        padding: 10,
        textAlign: "center",
        letterSpacing: 10,
    }
});