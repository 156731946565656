import React from 'react';
import { createStackNavigator } from '@react-navigation/stack'

import Home from '../pages/Home'
import InformEmail from '../pages/InformEmail'
import ValidateEmail from '../pages/ValidateEmail'
import Step from '../pages/Step'

const Stack = createStackNavigator();

function AuthRoutes() {
    console.log('NAO LOGADO')
    return (
        <Stack.Navigator>
            <Stack.Screen name="Home" component={Home} options={{headerShown: false, title: ""}}/>
            <Stack.Screen name="InformEmail" component={InformEmail} options={{headerStyle: {borderBottomWidth: 0}, title: ""}} />
            <Stack.Screen name="ValidateEmail" component={ValidateEmail} options={{headerStyle: {borderBottomWidth: 0}, title: ""}}/>
            <Stack.Screen name="Step" component={Step}  options={{headerStyle: {borderBottomWidth: 0}, title: "", headerBackTitle: ""}} />
        </Stack.Navigator>
    )
}

export default AuthRoutes;
