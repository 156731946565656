import React, {useContext, useEffect, useState} from 'react';
import { View, Text, TouchableOpacity, StyleSheet, FlatList } from 'react-native';
import {FlowContext} from '../../contexts/FlowContext'
import Tab  from '../../components/Tab'
import { showMessage } from "react-native-flash-message";
import { useRoute, RouteProp, useNavigation } from '@react-navigation/native'
import { Styles } from '../../constants';

type StepParam = {
    Step: {
        step_id: number
    }
}

type OrderRouteProps = RouteProp<StepParam, 'Step'>;

export default function Step({navigation}) {
    console.log('Step')
    const route = useRoute<OrderRouteProps>();
    const [nextStep, setNextStep] = useState<any>();
    const [executed, setExecuted] = useState<boolean>(false);
    const {getStep, getNextStep, appendResponse, reset, voltar, getAnswers, setLoading, setSkipHome} = useContext(FlowContext);
    const currentStepId = route.params ? route.params.step_id: 1;
    const step = getStep(currentStepId);

    useEffect(() => {
        setLoading(false);
        appendResponse(currentStepId);
        setNextStep(getNextStep(currentStepId));
    }, []);

    React.useEffect(
        () =>
          navigation.addListener('beforeRemove', (e) => {
            if (e.data.action.type == 'RESET') return;
            if (executed) {
                return;
            }
            console.log(e);
            e.preventDefault();
            voltar(navigation);
            setExecuted(true);
          }),
        [navigation]
      );

    function navigate(next_step: Step) {
        if(!next_step) {
            return;
        }
        appendResponse(next_step.id);

        navigation.navigate('Step', {step_id: next_step.id});
    }
    
    function getAnswerNextStep(answer_step_id: number){
        var next_step = getNextStep(answer_step_id);
        if (next_step == undefined || next_step == null) {
            showMessage({
                message: "Diagnóstico improvável",
                type: "warning",
            });
            return;
        }
        appendResponse(answer_step_id);
        return next_step
    }

    function InformationView() {
        let x = getNextStep(currentStepId);
        return (
            <View style={styles.container}>
                <View style={styles.inner_container}>
                    <Text style={styles.title}>{step?.title}</Text>
                    <Text style={styles.body}>{step?.body}</Text>
                    <Tab style={styles.tab} step={step}/>
                </View>
                {x?.id && <TouchableOpacity style={styles.button} onPress={() => navigate(x)}>
                        <Text style={styles.buttonText}>Próximo</Text>
                </TouchableOpacity>}
                {!x?.id && <TouchableOpacity style={styles.button} onPress={() => reset(navigation)}>
                        <Text style={styles.buttonText}>Voltar para início</Text>
                </TouchableOpacity>}
            </View>
        )
    }

    function QuestionView() {
        var answers = getAnswers(currentStepId);
        return (
        <View style={styles.container}>
            <View style={styles.question_inner_container}>
                <Text style={styles.title}>{step?.title}</Text>
                <Text style={styles.body}>{step?.body}</Text>
            </View>
            <FlatList style={styles.flatList} data={answers}
                renderItem={({item}) => 
                    <TouchableOpacity style={styles.responseButton} onPress={() => navigate(getAnswerNextStep(item.id))}>
                        <Text style={styles.responseButtonText}>{item.title}</Text>
                        <Text style={styles.buttonArrow}>></Text>
                    </TouchableOpacity>
                    }
            />
        </View>
    )
    }

    if(step?.type == 'information'){
        return InformationView();
    } else {
        return QuestionView();
    }
}

const styles = StyleSheet.create({
    inner_container: {
        width: '75%',
        flex: 1
    },
    question_inner_container: {
        width: '75%',
    },
    title: {
        marginTop: 10,
        fontSize: Styles.titleSize,
        color: Styles.titleColor,
        marginBottom: 20,
        paddingHorizontal: 10,
    },
    body: {
        fontSize: Styles.detailsSize,
        paddingHorizontal: 1,
        marginBottom: 30,
        textAlign: 'justify'
    },
    button: {
        width: '100%',
        height: 65,
        backgroundColor: Styles.blue,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 24,
    },
    buttonText: {
        fontWeight: 'bold',
        color: 'white',
        fontFamily: Styles.fontFamily,
        fontSize: Styles.buttonTextSize,
        backgroundColor: '#1595D2'
    },
    responseButtonText: {
        fontFamily: Styles.fontFamily,
        fontSize: Styles.buttonTextSize,
        width: '90%',
        fontWeight: 'bold',
        color: Styles.blueLight,
        justifyContent: 'center',
        alignItems: 'center',
    },
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: 'white',
        width: '100%'
    },
    flatList: {
        width: '75%'
    },
    responseButton: {
        padding: 12,
        fontFamily: Styles.fontFamily,
        fontSize: Styles.buttonTextSize,
        fontWeight: 'bold',
        borderColor: Styles.blueLight,
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 4,
        marginRight: 20,
        width: '100%',
        textAlignVertical: 'middle',
        flexDirection: 'row',
        marginBottom: 20
    },
    buttonArrow: {
        fontFamily: Styles.fontFamily,
        fontSize: 24,
        fontWeight: 'bold',
        color: Styles.blueLight,
        marginRight: 10,
        textAlignVertical: 'middle'
    }
});
