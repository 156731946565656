import React, {useState} from 'react';
import { api } from '../../services/api'
import { View, Text, TextInput, StyleSheet, TouchableOpacity, ActivityIndicator } from 'react-native';
import { showMessage } from "react-native-flash-message";
import { Styles } from '../../constants';

export default function InformEmail({navigation}) {
    const [loading, setLoading] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    
    async function sendEmail(email: string) {
        try{
            setLoading(true);
            const response = await api.post('/auth/email', {
                email: email
            });
            if (response.status == 200) {
                console.log('email enviado com sucesso');
            } else {
                console.log('erro ao enviar email', response);
            }
        }
        catch(err){
            console.log('exception ao enviar email', err);
            return false;
        }
        finally {
            setLoading(false);
            return true;
        }
    }

    async function handleStart() {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (!reg.test(email)) {
            showMessage({
                message: "Email inválido. Por favor informe email válido.",
                type: "danger",
            });
            return;
        }
        setLoading(true);
        const success = await sendEmail(email);
        setLoading(false);
        console.log(success)
        if (success){
            navigation.navigate('ValidateEmail', {email: email});
        } else {
            showMessage({
                message: "Erro ao enviar email. Por favor tentar novamente",
                type: "danger",
            });
        }
    }

    if (loading){
        return (
            <View style={{
                flex:1,
                backgroundColor: 'white',
                justifyContent: 'center',
                alignItems: 'center'
                }}
            >
                <ActivityIndicator size={60} color='#3d3d3d' />
            </View>
        )
    }

    return (
        <View style={styles.container}>
            <View style={styles.inner_container}>
                <Text style={styles.title}>Digite um e-mail válido</Text>
                <Text style={styles.description}>Vamos enviar um código de verificação e será necessário informá-lo na tela seguinte.</Text>
                    <TextInput
                        style={styles.input}     
                        placeholderTextColor="#F0F0F0"
                        value={email}
                        onChangeText={setEmail}
                        onSubmitEditing={handleStart}
                    />
                <TouchableOpacity style={styles.button} onPress={handleStart}>
                    <Text style={styles.buttonText}>Enviar</Text>
                </TouchableOpacity>
            </View>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: 'white'
    },
    inner_container: {
        width: '75%'
    },
    title: {
        marginTop: 20,
        fontSize: Styles.titleSize,
        color: Styles.titleColor,
        marginBottom: 30,
        fontFamily: Styles.fontFamily
    },
    description: {
        marginTop: 20,
        fontSize: Styles.detailsSize,
        color: Styles.titleColor,
        marginBottom: 40,
        fontFamily: Styles.fontFamily
    },
    button: {
        width: '100%',
        height: 40,
        backgroundColor: '#1595D2',
        borderRadius: 4,
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonText: {
        fontSize: Styles.buttonTextSize,
        fontWeight: 'bold',
        color: 'white'
    },
    input: {
            fontSize: Styles.buttonTextSize,
            fontWeight: 'normal',
            color: 'black',
            width: '100%',
            height: 40,
            borderColor: '#1595D2',
            borderRadius: 4,
            marginBottom: 20,
            borderWidth: 1,
            padding: 5,
            textAlign: "center"
    }
});
