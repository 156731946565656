import React, { useState, createContext, ReactNode, useEffect } from 'react'
import { api } from '../services/api'
import AsyncStorage from '@react-native-async-storage/async-storage'

type FlowContextData = {
    flow: Step[];
    getFlow: () => Promise<boolean>;
    getStep: (step_id: number) => Step | null;
    getAnswers: (step_id: number) => Step[];
    validateEmail: (email: string, code: string) => Promise<boolean>;
    getNextStep: (stepId: number) => Step | null;
    appendResponse: (stepId: number) => void;
    popResponse: () => void;
    voltar: () => void;
    reset: () => void;
    loading: boolean;
    setLoading: (loading: boolean) => void;
    skipHome: boolean;
    setSkipHome: (loading: boolean) => void;
    token: string;
}

type Step = {
    id: number;
    name: string;
    title: string;
    body: string;
    type: string;
}

type Flow = {
    name: string;
    flow: { [id: number] : FlowStep; }
}

type FlowStep = {
    flow: { [id: number] : FlowStep; }
}

type FlowProviderProps = {
    children: ReactNode;
}

export const FlowContext = createContext({} as FlowContextData);

export function FlowProvider({children}: FlowProviderProps){
    const [flow, setFlow] = useState<Step[]>([]);
    const [flowSequence, setFlowSequence] = useState<Flow[]>([]);
    const [flowResponses, setFlowResponses] = useState<Int32List>([]);
    const [token, setToken] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [skipHome, setSkipHome] = useState<boolean>(false);

    useEffect(() =>{
        async function getStoredToken() {
            const storedToken = await AsyncStorage.getItem('@token');
            if (!storedToken) {
                console.log('sem token')
                await AsyncStorage.removeItem('@token');
                setToken('');
                return null;
            }
            let token = parseJwt(storedToken);
            if (!token || !token?.exp) {
                console.log(`token vazio ${token?.exp}`)
                await AsyncStorage.removeItem('@token');
                setToken('');
                return null;
            }

            if(token.exp < (Date.now()/1000)) {
                console.log(`token expirou ${token.exp}`)
                await AsyncStorage.removeItem('@token');
                setToken('');
                return null;
            }
            console.log(`possui token ${storedToken}`);
            setToken(storedToken);

            return storedToken;
        }

        getStoredToken();
    }, []);

    useEffect(() =>{
        async function getStoredFlow() {
            const storedFlow = await AsyncStorage.getItem('@flow');
            let parsedFlow = JSON.parse(storedFlow || '[]');

            const storedFlowSequence = await AsyncStorage.getItem('@flowSequence');
            let parsedFlowSequence = JSON.parse(storedFlowSequence || '[]');

            if(parsedFlow.length > 0 && parsedFlowSequence.length > 0) {
                setFlow(parsedFlow);
                setFlowSequence(parsedFlowSequence);
            }
            else {
                await getFlow();
            }
        }
        if (token) {
            getStoredFlow();
        }
    }, [token]);

    function parseJwt (token: string) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    
        return JSON.parse(jsonPayload);
    }
    
    async function getFlow(): Promise<boolean> {
        try{
            const response = await api.get('/steps/');
            console.log('Resposta: ' + JSON.stringify(response.data));
            let flowData = response.data['steps'];
            let flowSequenceData = response.data['flows'];
            await AsyncStorage.setItem('@flow', JSON.stringify(flowData));
            await AsyncStorage.setItem('@flowSequence', JSON.stringify(flowSequenceData));
            setFlow(flowData);
            setFlowSequence(flowSequenceData);
            return true;
        }
        catch(err){
            console.log('erro ao acessar get flow', err);
            return false;
        }
    }

    async function validateEmail(email: string, code: string) {
        try{
            const response = await api.post('/auth/email/validate', {
                email: email,
                code: code
            });
            if (response.status == 200) {
                console.log('email validado com sucesso');
                api.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`;
                await AsyncStorage.setItem('@token', JSON.stringify(response.data.access_token));
                var success = await getFlow();
                if (success) {
                    setToken(response.data.access_token);
                    setSkipHome(true);
                }
                return true;
            } else {
                console.log('erro ao validar email', response);
                return false;
            }
        }
        catch(err){
            console.log('erro ao validar email', err);
            return false;
        }
    }

    function getAnswers(questionStepId: number): Step[] {
        let answers = flow.filter((item) => {
            return item.type == 'answer-option' && item.question_step_id == questionStepId
        })
        return answers;
    }

    function getStep(stepId: number): Step | null {
        let step = flow.filter((item) => {
            return item.id == stepId
        })
        if (step.length > 0){
            return step[0];
        }
        else {
            return null;
        }
    }

    function getNextStep(stepId: number): Step | null {
        var responses = [...flowResponses];
        if (responses.length > 0 && responses[responses.length - 1] != stepId){
            responses.push(stepId);
        }
        let flow = flowSequence.filter((item) => {
            var result = responsesFollowFlowSequence(responses, item.flow);
            return result === -1 || result > 0; 
        });
        if (flow.length > 0) {
            var nextStep = responsesFollowFlowSequence(responses, flow[0].flow);
            if (nextStep === -1) {
                // chegou no fim do fluxo
                return null;
            }
            return getStep(nextStep);
        }
        else {
            return null;
        }
    }

    function responsesFollowFlowSequence(responses: Int32List, _flowSequence: any, index: number=0): number {
        if (_flowSequence == null) {
            // chegou ao fim do fluxo
            return -1;
        }
        if (index >= responses.length) {
            return parseInt(Object.keys(_flowSequence)[0]);
        }
        var response = responses[index];

        var hasResponse = _flowSequence.hasOwnProperty(response)
        if (hasResponse) {
            var current = _flowSequence[response];
            return responsesFollowFlowSequence(responses, current, index + 1);
        }
        return 0
    }

    function voltar(navigation) {
        console.log('voltar');
        if (flowResponses.length > 0){
            popResponse();
            if (flowResponses.length > 0) {
                var last_step_id = flowResponses[flowResponses.length - 1];
                var last_step = getStep(last_step_id);
                if (last_step.type == 'answer-option') {
                    popResponse();
                    last_step_id = flowResponses[flowResponses.length - 1];
                    last_step = getStep(last_step_id);
                }
                
                navigation.navigate('Step', {step_id: last_step_id});
            } else {
                reset(navigation);
            }
        } else {
            reset(navigation);
        }
    }

    function reset(navigation) {
        setFlowResponses([]);
        navigation.reset({
            index: 0,
            routes: [{ name: 'Home' }],
        });
    }
    
    function appendResponse(stepId: number) {
        if (flowResponses.length > 0 && flowResponses[flowResponses.length - 1] == stepId){
            return;
        }
        flowResponses.push(stepId);
        setFlowResponses(flowResponses);
        console.log(`responses: ${flowResponses}`)
    }

    function popResponse() {
        let element = flowResponses.pop();
        setFlowResponses(flowResponses);
        return element;
    }

    return (
        <FlowContext.Provider value={{flow, getFlow, getStep, getAnswers, validateEmail, getNextStep, appendResponse, popResponse, voltar, reset, loading, setLoading, skipHome, setSkipHome, token}}>
            {children}
        </FlowContext.Provider>
    )
}
