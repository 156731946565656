import 'react-native-gesture-handler';
import { StatusBar } from 'expo-status-bar';
import { NavigationContainer } from '@react-navigation/native';
import Routes from './src/routes';
import { useFonts } from 'expo-font';
import FlashMessage from "react-native-flash-message";
import {FlowProvider} from './src/contexts/FlowContext'

export default function App() {
  const [loaded] = useFonts({
    Montserrat: require('./assets/fonts/Montserrat-VariableFont_wght.ttf'),
  });
  
  return (
    <NavigationContainer>
      <FlowProvider>
        <StatusBar style="auto" backgroundColor="white" translucent={false} />
        <Routes />
        <FlashMessage position="top" />
      </FlowProvider>
    </NavigationContainer>
  );
}
