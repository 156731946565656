import type { ScaledSize } from "react-native";
import { Dimensions } from "react-native";

import { isWeb } from "../utils";

export const ElementsText = {
  AUTOPLAY: "AutoPlay",
};

export const window: ScaledSize = isWeb
  ? {
    ...Dimensions.get("window"),
    width: 375,
  }
  : Dimensions.get("window");
  
export const Styles = {
    fontFamily: 'Montserrat, sans-serif',
    titleSize: 30,
    titleColor: 'black',
    detailsSize: 17,
    detailsColor: 'black',
    detailsBackground: 'white',
    blue: '#1595D2',
    blueLight: '#00CFD3',
    buttonTextSize: 18
};