import React, {useContext, useEffect} from 'react';
import { View, Text, StyleSheet, Image, TouchableOpacity, ActivityIndicator } from 'react-native';
import { Styles } from '../../constants';
import { FlowContext } from '../../contexts/FlowContext';
import imgSrc from './assets/logo-fousp.png';

export default function Home({navigation}) {
    console.log('Home');
    const { token, loading } = useContext(FlowContext);

    async function handleStart() {
        if (!!token) {
            console.log('handleStart - Step');
            navigation.navigate('Step', {step_id: 1});
        } else {
            console.log('handleStart - InformEmail');
            navigation.navigate('InformEmail');
        }
    }

    if (loading){
        return (
            <View style={{
                flex:1,
                backgroundColor: 'white',
                justifyContent: 'center',
                alignItems: 'center'
                }}
            >
                <ActivityIndicator size={60} color='#3d3d3d' />
            </View>
        )
    }

    return (
        <View style={styles.container}>
            <Image
                style={styles.logo}
                source={{uri: imgSrc}}
            />
            <Text style={styles.title}>Diagnóstico em Endodontia</Text>
            <TouchableOpacity style={styles.button} onPress={handleStart}>
                <Text style={styles.buttonText}>Iniciar</Text>
            </TouchableOpacity>
        </View>
    )
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: 'white'
    },
    logo: {
        marginTop: 30,
        width: '75%',
        display: 'flex',
        resizeMode: 'contain',
        height: '100px'
    },
    title: {
        fontSize: 40,
        color: Styles.titleColor,
        marginTop: 150,
        width: '75%',
        textAlign: 'center',
        marginBottom: 100,
        fontFamily: Styles.fontFamily
    },
    button: {
        width: '50%',
        height: 60,
        backgroundColor: '#1595D2',
        borderRadius: 4,
        justifyContent: 'center',
        alignItems: 'center'
    },
    buttonText: {
        fontSize: Styles.buttonTextSize,
        fontWeight: 'bold',
        color: 'white'
    }
});
