import axios from 'axios';

const api = axios.create({
    baseURL: 'https://endo-api-vxuaiqu5pq-uc.a.run.app/',
    //baseURL: 'http://localhost:8000/',
    headers: {
        'Content-Type': 'application/json',
    }
})

export {api};
