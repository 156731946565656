import * as React from 'react';
import { View, Text, StyleSheet, TouchableOpacity, FlatList, Linking, Image } from 'react-native';
import { Styles } from '../../constants';
import { Feather } from '@expo/vector-icons'

export default function Tab({step}: any) {
    const [tab, setTab] = React.useState('second')

    const hasImage = step?.image_set.length > 0;
    const hasReference = step?.document_set.length > 0;
    function ImageTab() {
        if (!hasImage) {return(<></>)}
        return (
            <View style={styles.imageTab} >
                <FlatList data={step?.image_set}
                        renderItem={({item}) => 
                            <View style={styles.imageCard}>
                                <Image
                                    style={styles.image}
                                    source={{uri: item.path}}
                                />
                                <Text style={styles.imageTitleText}>{item?.title}</Text>
                                <Text style={styles.imageBodyText}>{item?.body}</Text>
                            </View>
                            }
                    />
            </View>
        )
    }

    function ReferenceTab() {
        if (!hasReference) {return(<></>)}
        return (
            <View style={styles.referenceTab} >
                <FlatList data={step?.document_set}
                        renderItem={({item}) => 
                            <View style={styles.flatListView}>
                                <Text style={styles.hyperlinkStyle} onPress={() => {
                                    Linking.openURL(item?.path);
                                }}>{item?.title}</Text>
                                <Feather name="external-link" color="#FF3F4b" size={25} />
                            </View>
                            }
                    />
            </View>
        )
    }

    function getView() {
        switch (tab) {
            case 'second':
                return ImageTab()
            case 'third':
                return ReferenceTab()
            default:
                return ImageTab()
        }
    }

    return (
        <View style={styles.container}>
            <View style={styles.tabs}>
                {hasImage && <TouchableOpacity style={[styles.button, tab == 'second' ? styles.selectedButton: styles.none]} onPress={() => setTab('second')}>
                    <Text style={[styles.buttonText, tab == 'second' ? styles.selectedButtonText: styles.none]}>Imagens</Text>
                </TouchableOpacity>}
                {hasReference && <TouchableOpacity style={[styles.button, tab == 'third' ? styles.selectedButton: styles.none]} onPress={() => setTab('third')}>
                    <Text style={[styles.buttonText, tab == 'third' ? styles.selectedButtonText: styles.none]}>Referencias</Text>
                </TouchableOpacity>}
            </View>
            {getView()}
        </View>
    );
}


const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        alignContent: 'center',
        justifyContent: 'center'
    },
    tabs: { 
        flexDirection: 'row', 
        width: '100%',
        alignContent: 'center',
        justifyContent: 'center'
    },
    imageTab: { 
        flex: 1,
        width: '100%',
        alignContent: 'center',
        justifyContent: 'center',
    },
    referenceTab: { 
        flex: 1, 
        backgroundColor: 'white', 
        width: '100%'
    },
    button: {
        padding: 10,
        fontFamily: Styles.fontFamily,
        fontSize: Styles.buttonTextSize,
        fontWeight: 'bold',
        borderColor: Styles.blueLight,
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 4,
        marginRight: 20,
    },
    selectedButton: {
        backgroundColor: Styles.blueLight
    },
    buttonText: {
        color: Styles.blueLight
    },
    imageTitleText: {
        fontSize: 24,
        fontWeight: 'bold',
        textAlign: 'center',
        color: 'black'
    },
    imageBodyText: {
        marginTop: 10,
        fontSize: 16,
        marginLeft: 10,
        width:'90%',
        color: 'black'
    },
    selectedButtonText: {
        color: 'white'
    },
    flatListView: {
        width: '100%',
        flexDirection: 'row'
    },
    hyperlinkStyle: {
        color: 'blue',
    },
    imageCard: {
        marginTop: 20,
        width:'100%',
        display: 'flex',
        borderWidth: 1,
        borderColor: 'black',
        borderStyle: 'solid',
        alignContent: 'center',
        justifyContent: 'center',
        paddingTop: 0,
        paddingBottom: 10,
        height: 'auto'
    },
    image: {
        marginTop: 0,
        display: 'flex',
        width:'100%',
        resizeMode: 'cover',
        height: 300
    }
});